<template>
	<div class="view pa24">
		<div style="font-size: 26px;font-weight: bold;margin-bottom: 30px;">结算申请
			<span class="fs8 mr10 mb15 fw400" style="color: #51CDCB;">可用余额：500</span>
			<span class="fs8 mr10 mb15 fw400" style="color: #ff0000;">冻结余额：500</span>
			<span class="fs8 mr10 mb15 fw400" style="color: #49dc00;">结算类型：500</span>
		</div>
		<el-form style="width: 30%;" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
			<el-form-item label="提交金额" prop="money">
				<el-input clearable v-model="ruleForm.money" placeholder="请输入金额"></el-input>
			</el-form-item>
			<el-form-item label="到账金额" prop="dzMoney">
				<el-input clearable v-model="ruleForm.money" placeholder="系统计算"></el-input>
			</el-form-item>
			<el-form-item label="费用" prop="feiyong">
				<el-input clearable v-model="ruleForm.feiyong" placeholder="系统计算"></el-input>
			</el-form-item>
			<!-- <el-form-item label="结算银行卡" prop="bank">
		 <el-select style="width: 100%;" clearable v-model="ruleForm.bank" placeholder="请选择结算银行卡">
		 	<el-option v-for="(item,index) in fashiList" :label="item.name"
		 		:value="item.index">
		 	</el-option>
		 </el-select>
		</el-form-item> -->
			<el-form-item label="备注" prop="desc">
				<el-input clearable v-model="ruleForm.desc" type="textarea" :rows="3" placeholder="请输入备注"></el-input>
			</el-form-item>
			<el-form-item label="支付密码" prop="password">
				<el-input clearable v-model="ruleForm.password" placeholder="请输入支付密码"></el-input>
			</el-form-item>
			<el-form-item label="谷歌验证码" prop="code">
				<el-input clearable v-model="ruleForm.code" placeholder="请输入谷歌验证码"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="determine">提 交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	export default {
		name: '',
		data() {
			return {
				ruleForm: {
					money: "",
					dzMoney: "",
					feiyong: '',
					bank: '',
					desc: '',
					code: '',
					password: '',
				},
				rules: {
					money: [{
						required: true,
						message: "请输入提交金额",
						trigger: "blur"
					}],
					bank: [{
						required: true,
						message: "请选择结算银行卡",
						trigger: "blur"
					}],
					password: [{
						required: true,
						message: "请输入支付密码",
						trigger: "blur"
					}],
					code: [{
						required: true,
						message: "请输入谷歌验证码",
						trigger: "blur"
					}],
				},
			};
		},
		mounted() {
			// this.getCGSList()
		},

		methods: {
			async getCGSList() {
				let ajax = {
					acountId: localStorage.getItem('acountId')
				}
				getOfficeLogin(ajax).then((res) => {
					if (res.code !== 200) {
						return;
					}
					this.optionsCGS = res.data
				})
			},
			determine() {
				this.$refs.ruleForm.validate(async (valid) => {
					if (valid) {
						try {
							let data = {
								oldPassword: this.ruleForm.oldPassword,
								newPassword: this.ruleForm.newPassword,
								qrPassword: this.ruleForm.qrPassword,
								newPayPassword: this.ruleForm.newPayPassword,
								qrnewPayPassword: this.ruleForm.qrnewPayPassword,
								code: this.ruleForm.code,
							};
							this.$message({
								message: "正在保存",
							});
							if (this.editUpdate) {
								data.windowsConfigId = this.windowsConfigId
								await upWindowsConfig(data);
							} else {
								await addWindowsConfig(data);
							}
							this.$message.closeAll();
							this.$message({
								message: "保存成功",
								type: "success",
							});
							await this.queryPage();
						} catch (error) {
							this.$message.closeAll();
							this.$message({
								message: error.message,
								type: "error",
							});
						}
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 78px;
		height: 78px;
		//line-height: 175px;
		text-align: center;
	}

	/deep/ .avatar-coverImg .el-upload--text {
		width: 100px;
		height: 100px;
	}

	/deep/ .avatar-coverImg .avatar-uploader-icon {
		line-height: 100px;
	}

	.select-goods-list {
		margin-bottom: 20px;
		border-radius: 40px;
		box-sizing: border-box;
		padding: 0 20px;
		background: #ffffff;
		transition: all 0.4s;
	}

	.select-goods-list:hover {
		background: #f5f5f6;
		transition: all 0.4s;
		cursor: pointer;
	}

	/deep/ .disabled .el-upload--picture-card {
		display: none;
	}
</style>